export const PROOF_ACCESS_PERMISSIONS = {
  OWNED_ONLY: 1,
  TEAMS_ONLY: 2,
  EVERYTHING: 3,
  NONE: 4,
};

export const DOC_TYPES = {
  VIDEO: "video",
  HTML: "html",
  AUDIO: "audio",
  IMAGE: "image",
  DOCUMENT: "document",
  WEB_URL: "web_url"
}

export const ANNOTATION_TYPES = {
  DRAW: 'draw',
  ARROW: 'arrow',
  RECT: 'rect',
  POINT: 'point'
}

export const PROOF_VIEW_MODES = {
  APPROVER_MODE: 0,
  PROOF_OWNER_MODE: 1,
  COMMENTER_MODE: 2,
}

export const TYPES_ENUM = {
  STRING: 'string',
  FUNCTION: 'function'
}

export const DEFAULT_ACCOUNT_NAME = "Ashore";
export const DEFAULT_ACCOUNT_LOGO = "img/logo-large.svg"

export const DEFAULT_PADDING = {
   DEFAULT_X_PADDING: 15,
   DEFAULT_Y_PADDING: 10
}

export const CURSOR_TYPE = {
  DEFAULT: 'default',
  MOVE: 'move',
  POINTER: 'pointer'
}

export const SSO_PROVIDERS = {
  MICROSOFT: "microsoft",
  GOOGLE: "google"
}

export const NOTIFICATION_FREQUENCY_OPTIONS = {
  NEVER: 0,
  WHEN_USER_SESSION_ENDS: 1,
  EVERY_10_MINUTES: 2,
  EVERY_30_MINUTES: 3,
  EVERY_HOUR: 4,
  DAILY: 5
}

export const FILE_TYPE = {
  PDF: 'application/pdf',
}

export const PREFLIGHT_STATUS = {
  COMPLETED: 'Completed',
  PROCESSING: 'Processing',
  ERROR: 'Error',
  FAILED: 'Failed'
}

export const PLAN_SKU_TYPES = {
  FREE_PLAN: 'free',
  SUSPENDED_PLAN: 'suspended',
  TRIAL_PLAN: 'trial',
  PREMIUM_PLAN: 'premium'
}

export const PREFLIGHT_PRICES = {
  'preflights': 46,
  'preflights-annual': 33
};

export const PLAN_PUBLIC_NAMES = {
  FREE: 'Free',
  STANDARD: 'Standard',
  PREMIUM: 'Premium',
  SUSPENDED: 'Suspended',
}

export const PLAN_DESCRIPTION = {
  FREE_DESCRIPTION: "Perfect for learning Ashore",
  STANDARD_DESCRIPTION: "All of Ashore with limited branding",
  PREMIUM_DESCRIPTION: "Unlimited files and full branding"
}

export const PLAN_FEATURES = {
  FREE_FEATURES: [
    "Send 30 files per month",
    "Unlimited approvers",
    "Limited to one user",
    "1GB storage limit",
    "Full automation"
  ],
  STANDARD_FEATURES: [
    "All forever free features",
    "Send 150 files per user per month",
    "Unlimited storage for 1 year",
    "Integrate your email and logo",
    "1 custom property",
    "Unlock video and audio proofing",
    "Personalized training"
  ],
  PREMIUM_FEATURES: [
    "All standard features",
    "Send unlimited files per month",
    "Full branding(custom domain)",
    "Unlimited storage for 2 years",
    "API access",
    "Unlimited custom properties",
    "Personalized training"
  ]
}

export const FAQS = [
  {
    question: "What do I get with a paid plan?",
    answer: `<p>With a paid plan on Ashore, you get access to advanced features that streamline your proofing workflow, including:</p>
             <ul>
             <li>More Proofs – Send as many proofs as you need and invite unlimited reviewers.</li>
             <li>White Labeling – Customize Ashore with your branding.</li>
             <li>Integrations & API Access – Connect with third-party apps for seamless collaboration.</li>
             <li>Enhanced Storage – We store your files for up to 24 months!</li>
             </ul>
             <p class='last-para'>Paid plans are designed for teams that need more control, automation, and customization in their proofing process.</p>`,
  },
  {
    question: "Can I cancel my subscription at any time?",
    answer: `<p class='last-para'>Yes, you can cancel your Ashore subscription at any time. After cancellation, your account will be suspended, meaning you'll retain full access to your information but won't be able to send new files.</p>`,
  },
  {
    question: "Can I upgrade or downgrade plans?",
    answer: `<p class='last-para'>Yes, you can upgrade or downgrade your Ashore plan at any time. When upgrading, new features will be available immediately. If you downgrade, changes will immediately take effect.</p>`,
  },
  {
    question: "Do you offer a free trial?",
    answer: `<p class='last-para'>Ashore offers a Forever Free Plan that allows you to use the platform with some limitations. While you can send proofs and collaborate with reviewers, the free plan has restrictions on storage and the number of files you can manage. Additionally, you won't be able to integrate your email or white-label your account. If you need more flexibility and advanced features, upgrading to a paid plan is the way to go.</p>`,
  },
  {
    question: "Are there any additional fees?",
    answer: `<p class='last-para'>There are no additional fees beyond your subscription cost, but Ashore does offer add-on subscriptions that can be added to your plan to unlock additional features. One example is our Preflights tool, which ensures PDFs are print-ready in seconds. These optional add-ons provide enhanced functionality without requiring a full plan upgrade.</p>`,
  },
  {
    question: "Do you offer refunds?",
    answer: `<p class='last-para'>No, Ashore does not offer refunds under any circumstances. All purchases are final, so we recommend taking advantage of our Forever Free Plan to ensure Ashore meets your needs before upgrading to a paid plan.</p>`,
  },
]

export const MEASUREMENT_UNITS = {
  INCHES: "Inches",
  MILLIMETERS: "Millimeters"
};

export const MEASUREMENT_SYSTEMS = {
  IMPERIAL: "imperial",
  METRIC: "metric"
};
